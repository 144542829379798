<template>
  <div class="box-summary-payment">
    <data-summary-payment
      v-if="dataResponse != {}"
      :data-response="dataResponse"
      :products="products"
    />
  </div>
</template>
<script>
import DataSummaryPayment from '@/components/Payment/DataSummaryPayment.vue'
import { mapActions } from 'vuex'
export default {
  name: 'OrderDetailAddi',
  components: { DataSummaryPayment },
  data() {
    return {
      dataResponse: {},
      products: [],
    }
  },
  computed: {
    getDataAnalytics() {
      let arrayTemp = []
      this.products.forEach(product => {
        arrayTemp.push({
          name: product.producto_sku + '-' + product.producto_titulo,
          brand: product.marca,
          id: product.producto_sku,
          price: product.precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
    getDataProductsCRTO() {
      let arrayTemp = []
      this.products.forEach(product => {
        arrayTemp.push({
          id: product.producto_sku,
          price: product.precio,
          quantity: 1,
        })
      })
      return arrayTemp
    },
  },
  destroyed() {
    this.changeMenu({ menu: 'menu-base' })
    this.changeFooter({ footer: 'footer-derek' })
  },
  async mounted() {
    this.changeMenu({ menu: 'menu-simple' })
    this.changeFooter({ footer: 'footer-simple' })
    if (this.$route.params.referenceCode) {
      this.$store.state.loader = true
      var form = new FormData()
      form.append('referenceCode', this.$route.params.referenceCode)
      await this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/payment/sistecredito/response',
        data: form,
      })
        .then(response => {
          if (response.data.response.carrito_cerrado == 0) {
            if (response.data.response.checkoutAnonimo == 1) {
              this.$router
                .replace({
                  name: 'PaymentAnonymous',
                  params: {
                    tokenCarrito: response.data.response.carrito.token,
                    showmodalPaymentDecline: true,
                  },
                })
                .catch(() => {})
            } else {
              this.$router
                .replace({
                  name: 'Payment',
                  params: { showmodalPaymentDecline: true },
                })
                .catch(() => {})
            }
          } else {
            localStorage.removeItem('cartTokken')
            this.dataResponse = response.data.response.pedido
            this.products = response.data.response.pedido.productos
            this.$store.state.loader = false
          }
        })
        .catch(() => {
          this.$store.state.loader = false
          this.$router.replace({ name: 'Home' }).catch(() => {})
        })
    }
  },
  methods: {
    ...mapActions('layouts', ['changeMenu', 'changeFooter']),
  },
}
</script>
